import { Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import DownloadingIcon from '@mui/icons-material/Downloading';

const Subheading = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  text-transform: uppercase;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h2-size);
  font-family: var(--heading-desktop-h4);
`;
const SectionTitle = styled.div`
  width: 48rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Textp = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
`;
const Button1 = styled.div`
  border: 1px solid var(--border-primary);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  font-size: var(--text-medium-light-size);
`;
const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 18.75rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const Text2 = styled.div`
  position: relative;
  line-height: 150%;
  font-weight: 600;
`;
const Container = styled.div`
  background-color: var(--color-neutral-neutral-lightest);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--padding-5xs);
`;
const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Heading1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 140%;
  text-transform: uppercase;
`;
const Text3 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  font-family: var(--text-regular-light);
`;
const Content1 = styled.div`
  // width: 26rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--heading-desktop-h5-size);
  font-family: var(--heading-desktop-h4);
`;
const Content2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const PlaceholderImageParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const IconChevronRight = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const ButtonmuitextIcon = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
  gap: var(--gap-5xs);
  font-size: var(--text-regular-light-size);
  color: var(--primary-main);
`;
const Card = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-11xl);
  min-width: 300px;
`;
const Card1 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  font-size: var(--text-small-normal-size);
    flex-wrap: wrap;

`;
const BlogRoot = styled.section`
  align-self: stretch;
  background-color: var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--text-regular-light-size);
  color: var(--border-primary);
  font-family: var(--text-regular-light);
`;

const DownloadsSection = () => {
  return (
    <BlogRoot id="first-section">
      <Title>

        <SectionTitle>
          <Subheading>Merger Updates</Subheading>
          <Content>
            <Heading>IDM / Blackstone Merger Information</Heading>
            <Text1>
              <a href="javascript:void(Tawk_API.toggle())" style={{ color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}>
                Sign up to the notification alerts
              </a>
              {" "}to stay informed about the latest developments.
            </Text1>
          </Content>
        </SectionTitle>
      </Title>
      <Row>
      <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/IDMcoverAnnouncement-BSX_BRC-60assays030425.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>IDM - BSX ANNOUNCEMENT</Text2>
                </Container>
                <Text2>3rd April 2025</Text2>
              </Info>
              <Content1>
                <Heading1>Blackstone Unlocks High Grade Copper-Gold at Mankayan</Heading1>
                <Text3>
                  IDM International Limited refers to the announcement by Blackstone Minerals Limited titled “Blackstone Unlocks High Grade Copper-Gold at
                  Mankayan”.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDMcoverAnnouncement-BSX_BRC-60assays030425.pdf' download endIcon={<DownloadingIcon />} >
            Download IDM Cover Letter
          </ButtonmuitextIcon>
          <ButtonmuitextIcon variant="outlined" href='/BSX0472-BlackstoneUnlocksHighGradeCopper-GoldatMankayan.pdf' download endIcon={<DownloadingIcon />} >
            Download BSX Announcement
          </ButtonmuitextIcon>
        </Card>
         <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/IDM-InternationalLtd-AnnualReport31122024.png" />
            <Content2>
              <Info>
                <Container>
                  <Text2>REPORT</Text2>
                </Container>
                <Text2>December 2024</Text2>
              </Info>
              <Content1>
                <Heading1>ANNUAL REPORT FOR THE YEAR ENDED 31 DECEMBER 2024</Heading1>
                <Text3>
                 Your directors submit their Annual Report for IDM International Limited and its controlled entities for the year ended 31 December 2024
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-InternationalLtd-AnnualReport31122024.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/IDM-NoticeOf2025AGM.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>IDM - NOTICE</Text2>
                </Container>
                <Text2>31st March 2025</Text2>
              </Info>
              <Content1>
                <Heading1>NOTICE OF ANNUAL GENERAL MEETING OF SHAREHOLDERS</Heading1>
                <Text3>
                  IDM International Limited will be holding its Annual General Meeting on Tuesday 29th of April 2025.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-LETTER_AGMApril25.pdf' download endIcon={<DownloadingIcon />} >
            Download AGM Letter
          </ButtonmuitextIcon>
          <ButtonmuitextIcon variant="outlined" href='/IDM-NoticeOf2025AGM.pdf' download endIcon={<DownloadingIcon />} >
            Download NOTICE OF AGM
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/BSX-VisibleGold-Cover.png" />
            <Content2>
              <Info>
                <Container>
                  <Text2>IDM - BSX ANNOUNCEMENT - AMENDED</Text2>
                </Container>
                <Text2>6th March 2025</Text2>
              </Info>
              <Content1>
                <Heading1><b>AMENDED</b> - Visible Gold Discovered Above High Grade C-Au Porphyry</Heading1>
                <Text3>
                  IDM International Limited refers to the announcement by Blackstone Minerals Limited titled “Visible Gold Discovered Above High Grade C-Au Porphyry”.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDMAnnouncement-BSX_visual gold_AMEND060325.docx' download endIcon={<DownloadingIcon />} >
            Download IDM Cover Letter
          </ButtonmuitextIcon>
          <ButtonmuitextIcon variant="outlined" href='/BSXAnnouncement06032025-VisibleGold-AMENDED.pdf' download endIcon={<DownloadingIcon />} >
            Download BSX Announcement
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/Blackstone-Merger-COVER.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>ASX ANNOUNCEMENT</Text2>
                </Container>
                <Text2>6th February 2025</Text2>
              </Info>
              <Content1>
                <Heading1>Blackstone Merger to Acquire World Class Copper Gold Project</Heading1>
                <Text3>
                  Blackstone Minerals Limited is pleased to announce the Company has
                  entered into a binding scheme implementation deed with IDM International Limited.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/Blackstone-Merger-to-Acquire-World-Class-Copper-Gold-Project.PDF' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/BSXMergerPresCover.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>Presentation</Text2>
                </Container>
                <Text2>February 2025</Text2>
              </Info>
              <Content1>
                <Heading1>Blackstone Minerals Ltd (ASX:BSX) Merger - Investor Presentation February 2025</Heading1>
                <Text3>
                  Mankayan Cu-Au Project. A rare combination of scale, high-grade optionality, location & team.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM_International-BSXMerger-Presentation060225.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/IDM-BSXAnnoucement.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>Announcement</Text2>
                </Container>
                <Text2>February 2025</Text2>
              </Info>
              <Content1>
                <Heading1>IDM to Merge with ASX listed Blackstone Minerals Limited</Heading1>
                <Text3>
                  Please see attached Blackstone’s ASX announcement “Blackstone Merger to Acquire World Class Copper Gold Project” released on 6 February 2025.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM_Announcement-BSX_FINAL060225.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
      </Row>
      <Title>
        <SectionTitle>
          <Subheading>General Updates</Subheading>
          <Content>
            <Heading>Investor Update Documents</Heading>
            <Text1>
              <a href="javascript:void(Tawk_API.toggle())" style={{ color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}>
                Sign up to the notification alerts
              </a>
              {" "}to stay informed about the latest notification.
            </Text1>
          </Content>
        </SectionTitle>
      </Title>
      <Row>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/NoticeContinousDisclosure.png" />
            <Content2>
              <Info>
                <Container>
                  <Text2>NOTICE</Text2>
                </Container>
                <Text2>February 2025</Text2>
              </Info>
              <Content1>
                <Heading1>IDM Continuous Disclosure Notices</Heading1>
                <Text3>
                  IDM has adopted ASIC’s good practice guidance for website disclosure of material information.
                </Text3>
                <Text3>
                  <a href="javascript:void(Tawk_API.toggle())" style={{ color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}>
                    Sign up to the notification alerts
                  </a>
                  {" "}to stay informed about the latest notification.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>

          <ButtonmuitextIcon variant="outlined" href='/IDM-Announcement-Websitedisclosure_305486327-1.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/IDMAnnualReportCoverMock.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>REPORT</Text2>
                </Container>
                <Text2>July 2024</Text2>
              </Info>
              <Content1>
                <Heading1>ANNUAL REPORT FOR THE YEAR ENDED 31 DECEMBER 2023</Heading1>
                <Text3>
                 Your directors submit their Annual Report for IDM International Limited and its controlled entities for the year ended 31 December 2023
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-International-Ltd-December2023Report.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/AGMNoticeCover.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>NOTICE</Text2>
                </Container>
                <Text2>July 2024</Text2>
              </Info>
              <Content1>
                <Heading1>ANNUAL GENERAL MEETING OF SHAREHOLDERS</Heading1>
                <Text3>
                 IDM International Limited will be holding its Annual General Meeting at 10.30am (Perth time) on 16th August 2024 at Level 1, 33 Ord Street, West Perth Western Australia 6005.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-Letter-NoticeOf2024AGM_JWS.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>


        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/IDM-Update-Cover-June-2024.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>UPDATE</Text2>
                </Container>
                <Text2>June 2024</Text2>
              </Info>
              <Content1>
                <Heading1>Corporate Update</Heading1>
                <Text3>
                  IDM International Limited is pleased to provide an update on
                  the operations at its Mankayan copper-gold project in the
                  Philippines.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-International-Corporate-Update20240628.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/placeholder-image13@2x.png" />
            <Content2>
              <Info>
                <Container>
                  <Text2>UPDATE</Text2>
                </Container>
                <Text2>May 2024</Text2>
              </Info>
              <Content1>
                <Heading1>Corporate Update</Heading1>
                <Text3>
                  IDM International Limited is pleased to provide an update on
                  the operations at its Mankayan copper-gold project in the
                  Philippines.
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-ShareholderUpdate23-05-24.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/IDM-EGMNotice2023.jpg" />
            <Content2>
              <Info>
                <Container>
                  <Text2>Notice</Text2>
                </Container>
                <Text2>24 MARCH 2023</Text2>
              </Info>
              <Content1>
                <Heading1>NOTICE OF EXTRAORDINARY GENERAL MEETING
                  OF SHAREHOLDERS</Heading1>
                <Text3>Notice is hereby given that an Extraordinary General Meeting of Shareholders of IDM International</Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/IDM-NoticeOf2023EGM(Final)(003).pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/placeholder-image38@2x.png" />
            <Content2>
              <Info>
                <Container>
                  <Text2>UPDATE</Text2>
                </Container>
                <Text2>September 2023</Text2>
              </Info>
              <Content1>
                <Heading1>CORPORATE PRESENTATION</Heading1>
                <Text3>MANKAYAN COPPER-GOLD PORPHYRY PROJECT</Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
         <ButtonmuitextIcon variant="outlined" href='/IDM-CorporatePresentationMay2024.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
        <Card>
          <PlaceholderImageParent>
            <PlaceholderImageIcon alt="" src="/placeholder-image14@2x.png" />
            <Content2>
              <Info>
                <Container>
                  <Text2>REPORT</Text2>
                </Container>
                <Text2>27 December 2022</Text2>
              </Info>
              <Content1>
                <Heading1>Independent Technical Assessment</Heading1>
                <Text3>
                  Independent Technical Assessment and Valuation Report for
                  Mankayan Copper-Gold project, Philippines
                </Text3>
              </Content1>
            </Content2>
          </PlaceholderImageParent>
          <ButtonmuitextIcon variant="outlined" href='/PH-CSL-IDM01-IER_Val27Dec2022.pdf' download endIcon={<DownloadingIcon />} >
            Download
          </ButtonmuitextIcon>
        </Card>
      </Row>
    </BlogRoot>
  );
};

export default DownloadsSection;
